<template>
  <moe-page title="新建问卷" class="survey">
    <moe-form
      ref="surveyForm"
      :showBack="false"
      :model="surveyParams"
      :rules="rules">
      <template v-if="active === 0">

        <div class="fwb mb-20 font-20">问卷详情</div>
        <el-form-item label="问卷名称" prop="name" label-width="100px">
          <el-input v-model.trim="surveyParams.name" placeholder="请输入问卷名称，2到20个文字组合" clearable maxlength="20"></el-input>
          <div class="color-info font-10 lh1 mt-10">问卷名称将作为问卷页面标题展示给用户</div>
        </el-form-item>

        <el-form-item label="问卷时间" prop="startTime" label-width="100px">
          <el-date-picker
            placement="bottom-start"
            v-model="datetime"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            @change="(datetime) => ([surveyParams.startTime, surveyParams.endTime] = datetime || ['', ''])" />
        </el-form-item>

        <el-form-item label="问卷奖励" prop="lotteryCount" label-width="100px">
          <div class="df aic wsn">
            <el-checkbox v-model="surveyParams.sendLottery"></el-checkbox>
            <div class="ml-10 mr-10">抽奖活动编号</div>
            <div class="min-width-200">
              <el-input
                :disabled="!surveyParams.sendLottery"
                :value="surveyParams.lotteryId"
                @input="(value) => surveyParams.lotteryId = $moe_formatter.formatterId(value)"
                placeholder="请输入抽奖活动编号"
                maxlength="10">
              </el-input>
            </div>
            <div class="ml-10 mr-10">赠送抽奖次数</div>
            <div class="min-width-200">
              <el-input
                :disabled="!surveyParams.sendLottery"
                :value="surveyParams.lotteryCount"
                @input="(value) => surveyParams.lotteryCount = $moe_formatter.formatterInteger(value)"
                placeholder="请输入抽奖次数"
                maxlength="10">
              </el-input>
            </div>
          </div>
        </el-form-item>

        <el-form-item label="问卷限制" prop="submitLimit" label-width="100px">
          <div class="df aic wsn">
            <div class="mr-10">每个ID限制填写</div>
            <el-input
              class="w-200"
              :value="surveyParams.submitLimit"
              @input="(value) => surveyParams.submitLimit = $moe_formatter.formatterInteger(value)"
              placeholder="请输入"
              maxlength="10">
              <template slot="append">次</template>
            </el-input>
          </div>
        </el-form-item>

        <el-form-item label="背景图片" label-width="100px">
          <moe-upload-file
            v-model="surveyParams.img"
            ossKey="SHOP_MEDIA"
            upload-text="上传图片"
            :default-file-list="imgList"
            :suffix="['jpg', 'png', 'jpeg']"
            @change="() => $refs.tableForm.validateField(`img`)" />
        </el-form-item>

        <el-form-item label="问卷介绍" prop="description" label-width="100px">
          <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 10 }" v-model="surveyParams.description" placeholder="请输入问卷介绍" maxlength="500"></el-input>
        </el-form-item>
      </template>

      <template v-if="active === 1">
        <div class="fwb mb-20 font-20">设置题目</div>
        <moe-card class="mb-20" v-for="(item, index) in surveyParams.questions" :key="index">
          <el-form-item label="题目类型" :prop="`questions.${index}.type`" label-width="100px" :rules="rules.type">
            <moe-select class="w-200" type="questionnaireSurveyTypeList" v-model="item.type" placeholder="请选择题目类型"></moe-select>
          </el-form-item>


            <div class="df aic wsn" style="width: 100%;">
              <div class="survey-questions__title">
                <div class="mr-5 color-danger" v-if="item.required">*</div>
                <div class="mr-10">{{ `${index + 1}.` }}</div>
              </div>
              <div class="df1">
                <el-form-item :prop="`questions.${index}.title`" :rules="rules.title">
                  <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" v-model.trim="item.title" placeholder="请输入题目名称" clearable maxlength="100" show-word-limit></el-input>
                </el-form-item>
              </div>
              <div class="df aic ml-10 mb-20">
                <el-checkbox v-model="item.required" :border="true">必答</el-checkbox>
              </div>
            </div>

          <el-form-item label="题目图片" label-width="100px">
            <moe-upload-file
              v-model="item.images"
              :limit="2"
              ossKey="SHOP_MEDIA"
              upload-text="上传图片"
              :default-file-list="item.imagesList"
              @change="() => changeImage(index)" />
          </el-form-item>

          <!-- 单选题 | 多选题, 选项 -->
          <template v-if="['RADIO', 'CHECK'].includes(item.type)">
            <div v-for="(questionItem, questionIndex) in item.options" :key="questionIndex">
              <el-form-item :label="`选项${questionIndex + 1}`" :prop="`questions.${index}.options.${questionIndex}.title`" label-width="100px" :rules="rules.title2">
                <div class="df aic wsn">
                  <div v-if="item.type === 'RADIO'">
                    <moe-radio disabled></moe-radio>
                  </div>
                  <div class="mr-10" v-if="item.type === 'CHECK'">
                    <moe-checkbox disabled></moe-checkbox>
                  </div>
                  <el-input v-model.trim="questionItem.title" placeholder="请输入题目选项" clearable maxlength="50"></el-input>
                  <div class="ml-10">
                    <el-button v-if="questionIndex > 0" type="primary" size="mini" icon="el-icon-top" @click="handleOptionsClick('top', index, questionIndex)"></el-button>
                    <el-button v-if="questionIndex < (item.options.length - 1)" type="primary" size="mini" icon="el-icon-bottom" @click="handleOptionsClick('bottom', index, questionIndex)"></el-button>
                    <el-button :disabled="questionItem.id ? true : false" type="danger" size="mini" icon="el-icon-delete" @click="handleOptionsClick('delete', index, questionIndex)"></el-button>
                  </div>
                </div>
              </el-form-item>
            </div>

            <div class="df aic jcc">
              <el-form-item :prop="`questions.${index}.options`" :rules="rules.options">
                <el-button class="w-300" type="primary" icon="el-icon-circle-plus-outline" size="small" @click="handleOptionsAdd(index)">添加选项</el-button>
              </el-form-item>
            </div>
          </template>

          <el-form-item label-width="100px" v-if="['TEXT'].includes(item.type)">
            <el-input type="textarea" readonly :autosize="{ minRows: 2, maxRows: 4 }" placeholder="回答区域" maxlength="500"></el-input>
          </el-form-item>

          <div class="df aic jcfe">
            <!-- <el-button type="warning" icon="el-icon-setting" size="mini" @click="handleClick('setting', index)">逻辑设置</el-button> -->
            <el-button type="success" icon="el-icon-thumb" size="mini" @click="handleClick('add', index)">此题目下插入新题</el-button>
            <el-button v-if="index > 0" type="primary" icon="el-icon-top" size="mini" @click="handleClick('top', index)">上移</el-button>
            <el-button v-if="index < (surveyParams.questions.length - 1)" type="primary" icon="el-icon-bottom" size="mini" @click="handleClick('bottom', index)">下移</el-button>
            <el-button :disabled="item.id ? true : false" type="danger" icon="el-icon-delete" size="mini" @click="handleClick('delete', index)">删除</el-button>
          </div>
        </moe-card>

        <div class="df aic jcc">
          <el-form-item prop="questions">
            <el-button class="w-300" type="primary" icon="el-icon-circle-plus-outline" size="medium" @click="handleQuestionsAdd()">添加题目</el-button>
          </el-form-item>
        </div>
      </template>

      <template slot="tool">
        <el-button v-if="active === 0" type="info" icon="el-icon-close" @click="$moe_coordinator.navigateBack()">关闭</el-button>
        <el-button v-else type="primary" plain icon="el-icon-back" :loading="surveyLoad" @click="handleStep('back', active - 1)">{{ surveyLoad ? '保存中' : '上一步' }}</el-button>
        <el-button v-if="active === 1" type="primary" icon="el-icon-upload2" :loading="surveyLoad" @click="handleSubmit()">{{ surveyLoad ? '保存中' : '提交' }}</el-button>
        <el-button v-else type="primary" icon="el-icon-right" :loading="surveyLoad" @click="handleStep('right', active + 1)">{{ surveyLoad ? '保存中' : '下一步' }}</el-button>
      </template>
    </moe-form>
  </moe-page>
</template>

<script>
export default {
  name: 'QuestionnaireSurveyAdd',
  computed: {
    IS_EDIT() {
      return this.surveyParams.id ? true : false
    }
  },
  data() {
    const checkLotteryCount = (rule, value, callback) => {
      const { sendLottery, lotteryId, lotteryCount } = this.surveyParams;
      if (sendLottery) {
        if (!lotteryId) {
          return callback(new Error('请输入抽奖活动编号'));
        }
        if (!lotteryCount) {
          return callback(new Error('请输入赠送抽奖次数'));
        }
        callback();
      } else {
        callback();
      }
    }
    const verify = this.$moe_verify.verifyForm
    return {
      surveyParams: {
        id: '',
        name: '',
        startTime: '',
        endTime: '',
        sendLottery: false,
        lotteryId: '',
        lotteryCount: '',
        submitLimit: '',
        img: '',
        description: '',
        questions: [],
      },
      questionItem: {
        id: '',
        type: '',
        required: true,
        title: '',
        images: '',
        imagesList: [],
        options: [
          { title: '' },
          { title: '' },
        ],
        control: null,
        no: '', // 题目编号
        // control: {
        //   jump: {
        //     condition: '',
        //     target: ''
        //   },
        //   show: {
        //     condition: '',
        //     targets: [],
        //   }
        // },
      },
      optionItem: {
        title: '',
        id: '',
      },
      imgList: [],
      datetime: [],
      rules: {
        name: verify.isExtent(['请输入问卷名称', '长度为 2 ~ 20位'], '2~20'),
        startTime: verify.isEmpty('请选择问卷时间'),
        lotteryCount: [{ validator: checkLotteryCount, trigger: ['blur', 'change'] }],
        submitLimit: verify.isEmpty('请输入问卷限制'),
        description: verify.isEmpty('请输入问卷介绍'),
        questions: verify.isEmpty('请添加题目'),
        type: verify.isEmpty('请选择题目类型'),
        title: verify.isEmpty('请输入题目名称'),
        options: verify.isEmpty('请添加选项'),
        title2: verify.isEmpty('请输入题目选项'),
      },
      surveyLoad: false,
      active: 0,
    }
  },
  methods: {
    /** 添加题目 */
    handleQuestionsAdd() {
      this.surveyParams.questions.push(this.$moe_lodash.cloneDeepData(this.questionItem));
      this.$refs['surveyForm'].validate();
    },
    /** 添加单选题,多选题 选项 */
    handleOptionsAdd(index) {
      this.surveyParams.questions[index].options.push(this.$moe_lodash.cloneDeepData(this.optionItem));
      this.$refs['surveyForm'].validateField(`questions.${index}.options`);
    },
    /** 选项操作按钮 */
    handleOptionsClick(source, index, questionIndex) {
      let { options } = this.surveyParams.questions[index];
      switch (source) {
        case 'top':     //上移
          this.$moe_layer.confirm(`您确定要上移当前选项吗？`, () => {
            options.splice(questionIndex - 1, 2, options[questionIndex], options[questionIndex - 1]);
            this.$moe_msg.success('上移成功,提交后更新');
          });
          break;
        case 'bottom':  //下移
          this.$moe_layer.confirm(`您确定要下移当前选项吗？`, () => {
            options.splice(questionIndex, 2, options[questionIndex + 1], options[questionIndex]);
            this.$moe_msg.success('下移成功,提交后更新');
          });
          break;
        case 'delete':  //删除
          this.$moe_layer.confirm(`您确定要删除当前选项吗？`, () => {
            options.splice(questionIndex, 1);
          });
          break;
      }
    },
    /** 题目操作按钮 */
    handleClick(source, index) {
      let { questions } = this.surveyParams
      switch (source) {
        case 'setting': //逻辑设置
          break;
        case 'add':     //此题目下插入新题
          questions.splice(index + 1, 0, this.$moe_lodash.cloneDeepData(this.questionItem));
          break;
        case 'top':     //上移
          this.$moe_layer.confirm(`您确定要上移当前选项吗？`, () => {
            questions.splice(index - 1, 2, questions[index], questions[index - 1]);
            this.$moe_msg.success('上移成功,提交后更新');
          });
          break;
        case 'bottom':  //下移
          this.$moe_layer.confirm(`您确定要下移当前选项吗？`, () => {
            questions.splice(index, 2, questions[index + 1], questions[index]);
            this.$moe_msg.success('下移成功,提交后更新');
          });
          break;
        case 'delete':  //删除
          this.$moe_layer.confirm(`您确定要删除当前选项吗？`, () => {
            questions.splice(index, 1);
          });
          break;
      }
    },
    handleStep(source, newActive) {
      switch (source) {
        case 'back':
          this.$moe_layer.msgbox({
            title: '确定返回上一步?',
            message: `即将返回上一步，如有题目设置没保存，请取消并点击「提交」按钮提交`,
            showCancelButton: true,
            confirmButtonText: '取消',
            cancelButtonText: '上一步',
          }, () => {

          }, () => {
            this.active = newActive;
          })
          break;
        case 'right':
          this.$refs['surveyForm'].validate(() => {
            this.surveyLoad = true;
            const { id, name, startTime, endTime, sendLottery, lotteryId, lotteryCount, submitLimit, img, description } = this.surveyParams;
            let params = {
              id: id || '',
              name,
              startTime,
              endTime,
              sendLottery,
              lotteryId: sendLottery ? lotteryId : '',
              lotteryCount: sendLottery ? lotteryCount : '',
              submitLimit,
              img,
              description
            }
            if (this.IS_EDIT) {
              this.req_updateSurvey(params, newActive);
            } else {
              this.req_addSurvey(params, newActive);
            }
          })
          break;
      }
    },
    changeImage(index) {
      const item = this.surveyParams.questions[index];
      this.surveyParams.questions[index].imagesList = item.images ? item.images.split(',').map((image) => {
        return {
          url: this.$moe_yunHelper.getOssImageUrl(image),
          tempFilePath: image
        }
      }) : []
    },
    /** 提交问卷 */
    async handleSubmit() {
      this.$refs['surveyForm'].validate(async() => {
        this.surveyLoad = true;
        const { id, questions } = this.surveyParams;
        const { code, message } = await this.$moe_api.QUESTIONNAIRESURVEY_API.updateSurvey({
          id,
          questions: questions.map((questionItem, questionIndex) => {
            return {
              ...questionItem,
              options: questionItem.type === 'TEXT' ? [] : questionItem.options,
              no: questionIndex + 1
            }
          })
        });
        if (code === 200) {
          this.$moe_msg.success('操作成功', {
            completionHandler: () => {
              this.$moe_coordinator.navigateBack();
            }
          })
        } else {
          this.$moe_msg.error(message);
        }
        this.surveyLoad = false;
      })
    },
    /** 创建问卷 */
    async req_addSurvey(params, newActive) {
      const { code, result, message } = await this.$moe_api.QUESTIONNAIRESURVEY_API.addSurvey(params);
      if (code === 200) {
        this.active = newActive;
        this.$router.replace({
          query: {
            id: result.id
          }
        });
        this.req_getSurveyDetail();
      } else {
        this.$moe_msg.error(message);
      }
      this.surveyLoad = false;
    },
    /** 修改问卷 */
    async req_updateSurvey(params, newActive) {
      const { code, message } = await this.$moe_api.QUESTIONNAIRESURVEY_API.updateSurvey(params);
      if (code === 200) {
        this.active = newActive;
        this.req_getSurveyDetail();
      } else {
        this.$moe_msg.error(message);
      }
      this.surveyLoad = false;
    },
    /** 查询问卷详情 */
    async req_getSurveyDetail() {
      if (this.$route.query.id) {
        const { code, result, message } = await this.$moe_api.QUESTIONNAIRESURVEY_API.getSurveyDetail({ id: this.$route.query.id });
        if (code === 200) {
          const { id, name, startTime, endTime, sendLottery, lotteryId, lotteryCount, questions, submitLimit, img, description } = result;
          this.surveyParams = {
            id,
            name,
            startTime,
            endTime,
            sendLottery,
            lotteryId: sendLottery ? lotteryId : '',
            lotteryCount: sendLottery ? lotteryCount : '',
            submitLimit,
            img: img ? this.$moe_yunHelper.formatterImageUrl(img) : '',
            description,
            questions: questions && questions.length ? questions.map((item) => {
              let images = item.images ? item.images.split(',').map((image) => this.$moe_yunHelper.formatterImageUrl(image)).join(',') : '';
              let imagesList = item.images ? item.images.split(',').map((image) => {
                return {
                  url: image,
                  tempFilePath: this.$moe_yunHelper.getTempFilePath(image)
                }
              }) : [];
              return {
                ...item,
                images,
                imagesList,
                options: item.options.map((questionItem, questionIndex) => {
                  return {
                    ...questionItem,
                    id: questionIndex + 1
                  }
                })
              }
            }) : []
          };
          this.datetime = [startTime, endTime];
          if (img) {
            this.imgList = [{
              url: img,
              tempFilePath: this.$moe_yunHelper.getTempFilePath(img)
            }]
          }
        } else {
          this.$moe_msg.error(message);
        }
      }
    }
  },
  mounted() {
    this.req_getSurveyDetail();
  },
}
</script>

<style lang="scss">
.survey {
  &-questions__title {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 20px;
    width: 100px;
    margin-bottom: 20px;
  }
}
</style>